<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/8-1400.jpg')" title="UPRT - UPSET RECOVERY AND PREVENTION TRAINING" subtitle="Safety first" />
    <div class="program-description">
      <h1>What is a UPRT?</h1>
      <p>Whether you're looking to improve your skills or simply gain more confidence, our UPRT offers everything you need and more. Contact us today with any questions or for more information.</p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
